.install-drawer {
  padding: 10px 20px;

  .install-header {
    padding: 20px 0;
    display: flex;
    align-items: center;
  }

  button {
    margin-right: 20px;
  }
}

.ios-install-container {
  position: fixed;
  bottom: 20px;
  left: 5px;
  right: 5px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ios-install {
    padding: 10px 10px 0;
    color: $grey-900;
    background-color: $grey-400;
    border-radius: 10px;

  }

  .arrow-bottom {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid $grey-400;
  }
}
