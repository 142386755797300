.cancel-visit-container{
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  text-align: center;
  .property-info{
    margin-top: 10px;
    margin-bottom: 20px;
  }
  button{
    margin-top: 15px;
  }
  .property-image{
    height: 80px;
    width: 80px;
    border-radius: 18px;
    overflow: hidden;
    img{
      height: 100%;
      object-fit: cover;
    }
  }
}