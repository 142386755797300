.extendable-container {
  .extend-icon {
    transform: rotate(90deg);
    transition: .4s;
    color: $grey-700;
    &.extended {
      transform: rotate(-90deg);
    }
  }

  .list-container {
    overflow: hidden;
    transition: height .4s;
  }
}
