.dropzone {
  min-height: 300px;
  border: 1px $grey-500 dashed;
  cursor: pointer;
  padding: 10px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 10px;
  transition: 0.2s all;
  box-sizing: border-box;

  &.dragover {
    border: 3px $blue-700 dashed;
    background-color: $blue-200;

    .input-placeholder {
      color: $blue-700;

      svg {
        color: $blue-700;
      }
    }
  }

  .input-placeholder {
    text-align: center;
    width: 100%;
    color: $grey-700;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.2s color;

    svg {
      color: $grey-500;
      font-size: 60px;
    }
  }

  .thumbnail-container {
    height: 150px;
    width: 150px;
    box-shadow: $surround-shadow;
    position: relative;

    .spinner-container {
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba(130, 130, 130, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      color: white;
    }

    .delete-button {
      position: absolute;
      top: 5px;
      right: 5px;

      svg {
        color: white;
        background-color: $grey-500;
        border-radius: 999px;
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.upload-button-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

