//TODO clean this file important css is very ugly

.comment-container {
  display: flex;
}

.comment {
  padding: 10px 15px;
  border-radius: 14px;
  font-size: 14px;
  margin-bottom: 10px;
  word-break: break-word;

  .comment-header {
    display: flex;
    justify-content: flex-end;

    .comment-date {
      font-size: 12px;
      font-weight: 300;
    }
  }

  .comment-body {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;

    .comment-message {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
}


.document-section-tag {
  display: flex;
  padding: 10px 15px;
  border-radius: 999px;
  background-color: $grey-300;
  gap: 5px;

  &.full-section {
    background-color: $green-300;
  }

  &.other-section {
    background-color: $blue-200;

  }
}

.document-row-container {
  padding: 15px 20px 20px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex: 1;

  .document-row-content {
    border-radius: 2px;
    overflow: hidden;
  }

  .document-selector {
    flex: 11;
  }

  .document-tag-viewer {
    padding: 40px;
    flex: 8;

    h5 {
      margin-top: 0;
    }
  }
}

.completion-indicator {
  width: 5px;
  background-color: $red-500;

  &.optional {
    background-color: $blue-200;
  }

  &.complete {
    background-color: $green-500;
  }

  &.partial {
    background-color: $orange-400;
  }


}

.extendable-container {
  .selector-row {
    display: flex;
    cursor: pointer;
    border-bottom: solid 1px $grey-300;

    &.selected {
      background-color: $blue-100;
    }

    &:hover {
      background-color: $blue-50;
    }

    .section-content {
      display: flex;
      padding: 20px 10px;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      text-align: left;
      border: 2px green solid;
    }

    .content {
      display: flex;
      padding: 15px;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      text-align: left;

      .section-title {
        gap: 5px;
        align-items: center;

        svg {
          margin-right: 10px;
          font-size: 20px;
          color: $grey-500;
        }


        &.partial {
          svg {
            color: $orange-400;
          }
        }

        &.complete {
          svg {
            color: $green-500;
          }
        }

        .document-section-title-content {
          display: flex;
          gap: 5px;
          align-items: flex-end;
        }

        .no-message-indicator {
          text-align: left;
          color: $orange-600;
          font-size: 13px;
          padding: 5px 10px;
          border-radius: 8px;
          background-color: $orange-200;
          display: flex;
          align-items: center;

          svg {
            height: 17px;
          }
        }
      }

      h3 {
        margin: 0;
      }

      &.incomplete {
        flex-direction: column;
      }
    }

    &.full-section {
      background-color: $green-300;
    }

    &.other-section {
      background-color: $blue-200;
    }
  }

  .selector-sub-row {
    justify-content: space-between;
    padding: 15px 20px;

    &.docs {
      padding: 0 20px;
    }
  }


  &:last-child {
    .selector-sub-row {
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .info-msg {
    display: flex;
    gap: 10px;
    align-items: center;

    p {
      margin: 0;
    }

    blockquote {
      font-style: italic;
      background: $blue-100;
      padding: 20px;
      margin: 15px 0;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      .comment-header {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .comment {
        width: 100%;
        background-color: transparent;
        margin-bottom: 0;
      }
    }
  }
}

.document-tag-viewer {
  padding: 40px;
  width: 500px;

  h5 {
    margin-top: 0;
  }

  .awaited-documents {
    text-decoration: underline;
    margin-bottom: 10px;
  }
}


.document-section-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}


.pdf-preview-mobile {
  display: none;
  height: 200px;
  width: 150px;
  box-shadow: $surround-shadow-light;
  background-color: white;
  justify-content: center;
  align-items: center;
}

.file-preview {
  position: relative;
  overflow: hidden;
  height: 200px;
  width: 150px;
  box-shadow: $surround-shadow-light;

  .preview-actions-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .preview-actions {
      display: flex;
      background-color: white;
      border-radius: 0 0 8px 8px;
      box-shadow: $surround-shadow-light;
      border-top: none;
    }

  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.document-preview-list-container {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.document-preview-list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.document-preview-list-filler {
  flex: 1;
}

.upload-list-action-container {
  margin-left: 20px;
  margin-right: 20px;
}

.file-display {
  overflow: auto;
  max-width: 90vh;
  max-height: 90vh;

  .document {
    height: 90vh;
    width: 90vh;
    border: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.react-pdf__Document {
  height: 100%;

  canvas {
    width: 100% !important;
    height: unset !important;
  }
}

.file-upload-button-container {
  margin-left: 15px;

  svg {
    height: 50px;
    width: 50px;
  }
}


.document-preview-container {
  box-shadow: $surround-shadow-light;
  overflow: hidden;
  position: relative;

  .comment-preview {
    padding: 55px 10px 10px;
  }

  .preview-actions-container {
    position: absolute;
    gap: 10px;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row-reverse;
    padding: 10px;
    background: linear-gradient(rgba(141, 141, 141, 0.40), transparent);

    button {
      background-color: white;
      box-shadow: $surround-shadow-light;
    }
  }
}


.document-list {
  width: 100%;

  .document-data-container {
    display: flex;
    align-items: center;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    .document-data {
      flex: 1;
      padding: 10px;
      display: flex;
      background-color: $grey-300;
      align-items: center;
      word-break: break-word;
      justify-content: space-between;
      border-radius: 8px;
      margin-top: 5px;

      .document-actions {
        display: flex;
        flex-wrap: nowrap;
      }
    }
  }
}

.documents-upload-page-preview {
  display: flex;
  flex: 1;
  overflow: hidden;
  border-top: solid 1px $grey-400;

  .tmp {
    display: flex;

    .file-preview {
      display: none;
      order: -1;
    }
  }

  .document-tag-list-container {
    max-width: 43vw;
    position: relative;
    display: flex;
    overflow-y: hidden;

    .scrollable-indicator {
      position: absolute;
      top: 20px;
      left: 30px;

      svg {
        height: 1em;
        font-size: 40px;
      }

      z-index: 10;

      &.bottom {
        top: unset;
        bottom: 20px;
      }
    }

    .document-tag-list {
      overflow-y: auto;
      overflow-x: hidden;
      padding: 10px;
      background-color: white;
      box-shadow: $surround-shadow-light;
      text-align: center;


      .cancel-button {
        margin-bottom: 10px;
      }

      & > div {
        width: 100%;
      }

      .tag-section {
        width: 100%;
        word-break: break-word;
        display: flex;
        margin-bottom: 10px;
        border: dashed 1px $green-500;
        background-color: $green-200;
        border-radius: 8px;
        align-items: normal;
        overflow: hidden;

        .tag-name {
          padding: 40px 10px;
          flex: 1;
          position: relative;

          .tag-count {
            position: absolute;
            left: 5px;
            top: 5px;
            font-size: 13px;
            padding: 4px 10px;
            border-radius: 999px;
            background-color: $yellow-500;
            box-shadow: $surround-shadow-extra-light;
          }
        }
      }

      .file-preview-container {
        display: none;
      }

      .tag-section:only-child {
        border: dashed 1px $grey-500;
        background-color: $grey-300;
      }
    }
  }
}

.sortable-ghost {
  opacity: 0.1
}

.document-selected {
  border: solid 2px $orange-300;
}

.file-preview-container {
  height: fit-content;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}


.progression-bar-container {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  gap: 5px;

  .progression-step {
    height: 5px;
    border-radius: 999px;
    flex: 1;
    background-color: $grey-500;

    &.completed {
      background-color: $green-300;
    }
  }
}

.sortable-drag {
  .pdf-preview-mobile {
    display: flex;
  }

  .file-preview {
    display: none;
  }
}


.toggle-buttons-add-docs {
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0 20px;

  .MuiToggleButtonGroup-root {
    button.Mui-selected {
      background-color: $blue-100 !important;
      border-color: $blue-200 !important;
    }
  }
}


@media (max-width: $client-mobile-width) {
  .sortable-drag {
    .file-preview {
      display: none;
    }

    .pdf-preview-mobile {
      display: flex;
    }
  }
  .toggle-buttons-add-docs {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
  }
  .document-tag-viewer {
    padding: 15px;
    width: unset;
  }
  .document-preview-list {
    flex-direction: column;
    align-items: center;
  }
}

.delete-button-container {
  position: relative;

  .to-delete-number {
    position: absolute;
    font-size: 11px;
    border-radius: 999px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $red-700;
    color: white;
    top: 0;
    right: 0;
    box-shadow: $surround-shadow-light;
  }
}
