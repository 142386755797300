.clipboard-copy-button{
  position: relative;
}

.animated-tooltip{
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
  transition: .3s;
  opacity: 0;
  pointer-events: none;
  background-color: $grey-300;
  padding: 5px 15px;
  white-space: nowrap;
  border-radius: 999px;
  z-index: 999;
  font-weight: 400;
  font-size: 15px;
}

.animated-tooltip.show{
  bottom: -40px;
  opacity: 0.8;


}