.property-search-card {
  border-radius: 12px;
  overflow: hidden;
  width: 350px;

  background-color: white;


  &.seen {
    .property-image-container {
      .image-overlay {
        background-color: rgba(255, 255, 255, 0.67);
      }
    }

    color: $grey-400;
  }


  .property-image-container {
    position: relative;
    height: 175px;

    & > button {
      height: 100%;
      width: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .image-overlay {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      padding: 15px;
      pointer-events: none;

      .actions-container {
        display: flex;
        gap: 10px;
        height: fit-content;
        pointer-events: all;
      }
    }
  }
}



.property-search-card-content {
  padding: 10px 0;

  .property-price-row {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 200;
    font-size: 15px;
    margin-bottom: 5px;

    .property-price {
      font-weight: 700;
      font-size: 22px;
    }
  }

  .property-managed-by {
    padding: 0 10px 7px;
  }

  .property-address {
    display: flex;
    align-items: center;
    font-weight: 400;

    svg {
      height: 18px;
      width: 18px;
    }

    padding: 0 10px 7px;
  }
}

.property-info-container {
  padding: 5px 10px 0;
  border-top: solid 1px $grey-300;

  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
  font-size: 15px;
  font-weight: 300;

  svg {
    height: 16px;
    width: 16px;
  }

  .property-info {
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 3px;
  }
}