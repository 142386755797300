.progression-info {
  padding: 0 10px 0;
  margin-top: 15px;

  .finished-button {
    margin-left: 5px;
  }

  .progression-header {
    display: flex;
    justify-content: space-between;

    button {
      text-decoration: underline;
    }
  }
}

.action-bar {
  align-items: center;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-top: solid 1px $grey-400;
  padding: 3px;

  .side-actions-container {
    display: flex;
    gap: 10px;
  }

  .replace-button {
    text-decoration: underline;
  }
}

.file-carrousel-container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .file-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
  }

  form {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    .carousel-root {
      height: 100%;

      .carousel {
        height: 100%;

        .slider-wrapper {
          height: 100%;

          .slider {
            height: 100%;
          }
        }
      }
    }
  }
}