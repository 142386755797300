.search-property-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  .carousel-root {
    height: 100%;

    .carousel-slider {
      height: 100%;

      .slider-wrapper {
        height: 100%;

        .slider {
          height: 100%;

          .slide {
            height: 100%;

            .carrousel-image-container {
              height: 100%;
            }
          }
        }
      }
    }
  }

  .property-address-row {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    margin-bottom: 15px;

    .address {
      font-size: 16px;
    }

    .property-price {
      font-weight: 600;
    }
  }

  .property-managed-by {
    padding: 0;
    font-size: 14px;
    margin-bottom: 10px;
  }


  .property-source-row {
    display: flex;
    justify-content: space-between;
    color: $grey-500;

    a {
      text-decoration: underline;
    }
  }

  .back-button-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 10px 7px;

    .back-button {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;

      svg {
        font-size: 30px;
        height: 30px;
        color: $grey-800;
      }
    }

    .actions-container {
      display: flex;
      gap: 10px;

      .rounded-icon-button {
        box-shadow: $surround-shadow-light;
      }
    }
  }

  .image-gallery-container {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }


  .property-search-card-content {
    padding-bottom: 0;
  }

  .property-search-card {
    min-height: 220px;
    border-radius: 0 0 12px 12px;
    width: 100%;


    .property-image-container {
      height: 220px;

    }
  }


  .property-detail-content {
    padding: 0 10px 70px;
    width: 100%;
    box-sizing: border-box;
  }

  .info-row-container {
    display: flex;

    .property-search-card-content {
      flex: 1;
    }

  }

  .property-info-container {
    margin-top: 15px;
    border: none;
    background-color: $grey-400;

    border-radius: 10px;

    .property-info {
      flex: 1;
    }
  }

  .property-info-container {
    padding: 10px;
  }

  h4 {
    margin-top: 10px;
    border-top: solid 1px $grey-300;
    padding-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .candidate-section {
    h4 {
      border-top: none;
    }
  }

  .ask-viewing-button-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .property-price {
      font-size: 20px;
      font-weight: 600;
    }

    .property-price-sqm {
      font-size: 14px;
    }
  }
  .contact-button-container{
    display: flex;
    border: solid 1px $grey-900;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 10%);
    border-radius: 18px;
    overflow: hidden;
    button{
      padding: 13px;
      color: $grey-900;
      &:first-child{
        background-color: $grey-900;
        color: white;
      }
    }
  }

  .property-action-container {
    padding: 15px;
    background-color: white;
    border-radius: 12px;
    margin: 15px;
    box-shadow: $surround-shadow-extra-light;
    height: fit-content;

    & > button{
      margin: 0 10px;
    }

    .icon-actions {
      margin-top: 10px;
      justify-content: space-around;
      display: flex;
    }
  }

  .map-container {
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;


    .map-placeholder {
      height: 100%;
      width: 100%;
      max-width: 700px;
      background-color: $grey-400;
      overflow: hidden;
      border-radius: 15px;
    }

    .static-map-container {
      height: 100%;
      width: 100%;
      max-width: 700px;
      overflow: hidden;
      border-radius: 15px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.dynamic-map-modal {
  .MuiDialog-paper {
    height: 90vh !important;
    width: 90vw !important;
  }
}

.dynamic-map-container {
  width: 100%;
  height: 100%;

  .google-map-container {
    width: 100%;
    height: 100%;
  }
}

.home-icon-container {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin: 0;
    height: 25px;
  }

  background-color: $orange-600;
  color: white;
  border-radius: 999px;
  box-shadow: $surround-shadow;
}

.image-gallery {
  margin-right: 10px;
  flex: 1;
  display: grid;
  height: 25vw;
  min-height: 250px;
  max-height: 400px;
  grid-template-areas: "main main img1 img3"
                       "main main img2 img4";
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 5px;
  position: relative;

  .main-image {
    position: relative;

    .image-overlay {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      padding: 15px;
      pointer-events: none;
    }
  }

  .image-container {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }

    &:nth-child(1) {
      height: 100%;
      grid-area: main;
    }

    &:nth-child(2) {
      grid-area: img1;
    }

    &:nth-child(3) {
      grid-area: img2;
    }

    &:nth-child(4) {
      grid-area: img3;
    }

    &:nth-child(5) {
      grid-area: img4;
    }
  }
}

.no-result-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  img {
    height: 150px;
  }

  .no-result-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .action-link {
    text-decoration: underline;
    margin-right: 3px;
    margin-left: 3px;
  }
}

.modal-candidateId-actions {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    text-align: center;
    box-sizing: border-box;
    width: 100%;
  }

  button {
    width: 100%;
  }
}
.open-url-button{
  margin-right: 10px;
}