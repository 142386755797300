.property-page-selector {
  width: 100%;
  overflow-y: auto;
  flex: 1;

  .properties-container {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    .property-card-container {
      position: relative;
    }

    .property-select-button {
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }
}

.property-card {
  width: 100%;
  text-align: left;
  padding: 15px;
  border-radius: 8px;

  svg {
    height: 15px;
    min-width: 15px;
  }

  &.selected {
    background-color: $grey-300;
  }

  .property-identifier {
    margin-top: 5px;
    font-size: 14px;
    color: $grey-800;;
  }

  .property-price {
    margin-bottom: 0;
  }

  .property-address {
    display: flex;
    margin-top: 0;

    svg {
      margin-right: 3px;
    }

    .address-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .property-image {
    height: 270px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .property-info-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .property-dimension {
    color: $grey-800;
    font-size: 14px;
  }
}

.gallery-container {
  margin-bottom: 20px;
  position: relative;

  .see-more-button {
    position: absolute;
    right: 20px;
    bottom: 10px;
  }
}

.property-detail {
  width: 80vh;
  max-width: calc(100% - 40px);
  padding: 20px;
  max-height: calc(100vh - 250px);

  .property-identifier {
    width: 100%;
    color: $grey-800;
    font-size: 14px;
  }

  h2 {
    margin-top: 0;
  }

  .property-details-select-button {
    width: 100%;
    margin-top: 10px;
  }
}
.candidate-section {
  .candidate-description {
    .description {
      white-space: pre-wrap;
      word-break: break-word;
    }
    .button-description {
      text-decoration: underline;

      b {
        display: flex;
        align-items: center;
      }

      svg {
        height: 20px;
        margin: 0;
      }
    }
  }
}

@media (max-width: $client-mobile-width) {
  .gallery-container {
    .image-container {
      width: 100%;
      overflow: hidden;
      border-radius: 10px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}