form{
  .form-row{
    display: flex;
    gap: 10px;
    & > div{
      flex: 1;
    }
  }
  .form-row-always{
    display: flex;
    gap: 10px;
    & > div{
      flex: 1;
    }
  }
  .address-input-wrapper{
    margin-bottom: 10px;
  }

  .submit-button{
    margin-top: 10px;
  }
  .form-message{
    margin-bottom: 10px;
  }
}

@media (max-width: $client-mobile-width) {
  form {
    .form-row {
      flex-direction: column;
    }
  }
}