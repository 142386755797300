body {
  margin: 0;
  font-family: "Roboto";
}

button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  -webkit-appearance: none;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}

h5 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 500;
}

.firebaseui-idp-text-long {
  font-size: 0 !important;

  &:after {
    font-size: 15px;
    white-space: nowrap;
    // hack, still there is no other option yet with ui-firebase
    content: "Continuer avec google";
    padding-right: 15px;
  }
}

.firebaseui-idp-button {
  width: fit-content !important;
  max-width: unset !important;
}

.danger-item {
  color: $red-700 !important;
}

.icon-success {
  color: $green-300;
}

.icon-warning {
  color: $orange-300;
}

//TODO  ca n'a rien a faire dans root
.contact-item {
  .icon-warning {
    color: $orange-300;
    transform: translateY(3px);
  }
}

.filled-icon-button {
  background-color: white !important;
  color: black !important;

  &.Mui-disabled {
    background-color: $grey-300 !important;
    color: $grey-400 !important;
  }
}


.link-as-button {
  box-shadow: $surround-shadow-light;
  padding: 13px 20px;
  border-radius: 18px;
  color: white;
  background-color: $grey-900;
  display: inline-block;

  &.secondary {
    color: $grey-900;
    background-color: white;
  }

  &.disabled {
    background-color: $grey-600;
    color: white;
    pointer-events: none;
  }
}

.link-menu-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;

  a {
    padding: 6px 16px;
  }
}

.MuiDialog-paper {
  max-width: 80vw !important;
}

@media (max-width: $client-mobile-width) {
  .MuiDialog-paper {
    max-width: 95vw !important;
  }
}