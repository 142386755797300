.candidature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $grey-1000;
  gap: 10px;
  background: white;
  padding: 15px;
  border-radius: 10px;
  position: relative;
  box-shadow: $surround-shadow-extra-light;
  text-align: left;
  max-width: 400px;

  .property-image {
    position: relative;
    width: 100%;
    height: 110px;
    overflow: hidden;
    border-radius: 10px;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .image-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 15px;

      svg {
        font-size: 30px;
        color: white;
        stroke: $orange-600;
      }
    }
  }

  .candidature-info-content {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;

    .candidature-info {
      flex: 1;
      font-size: 14px;

      .candidature-info-body {
        width: 100%;

        .property-data {
          .property-city-container {
            display: flex;
            justify-content: space-between;

            .property-city {
              font-size: 18px;
              font-weight: 500;
            }

            a {
              text-decoration: underline;
              color: $grey-600;
            }
          }

          .property-info-body {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .property-info-container {
              flex: 1;
              border-top: none;
              max-width: 230px;
            }

            .property-price {
              font-size: 20px;
              font-weight: 500;
            }
          }
        }
      }

      .candidature-tag-container {
        flex: 1;
        display: flex;
        margin-top: 10px;
        gap: 5px;
        flex-wrap: wrap;

        & > div {
          &:last-child {
            flex: 1;
          }
        }


      }
    }
  }

  .agency-name {
    word-break: break-word;
    color: black;
    flex: 1;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    text-align: left;
  }

  .candidature-info-header {
    display: flex;
    font-size: 12px;
    color: $grey-500;
    align-items: center;
    width: 100%;
    justify-content: space-between;


  }

  &.selected {
    border: 1px $blue-200 solid;
    background-color: $blue-100;
  }

  .not-shared-icon {
    height: min-content;
    box-shadow: $surround-shadow-extra-light;

    .not-shared-icon {
      position: absolute;
      left: -5px;
      top: -5px;
      height: 24px;
      padding: 2px;
      background-color: white;
      color: $red-500;
      box-shadow: $surround-shadow-extra-light;
      border-radius: 999px;

      svg {
        font-weight: 500;
      }
    }
  }
}

.candidature-tag-container {
  flex: 1;
  display: flex;
  margin-top: 10px;

  p {
    margin: 0;
  }

  .candidature-tag {
    border-radius: 8px;
    font-size: 13px;
    padding: 5px 10px;
    background-color: $grey-300;
    display: flex;
    align-items: center;

    svg {
      height: 16px;
      color: $grey-700;
    }

    &.asked {
      background-color: $orange-200;

      svg {
        color: $orange-400;
      }
    }

    &.sent {
      background-color: $green-200;

      svg {
        color: $green-500;
      }
    }

    &.missing {
      background-color: $red-200;

      svg {
        color: $red-500;
      }
    }
  }
}

.cases-container {
  background: white;
  padding: 10px;
  border-radius: 18px;
  margin: 7px 10px 10px;
  box-shadow: $surround-shadow-mid-light;

  h4 {
    margin-top: 10px;
  }
}

.contact-item-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;

  .contact-item-content {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .error-message {
    color: $red-500;
  }
}

.contact-item {
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;

  .progress-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
}

.case-selector {
  padding: 8px 10px;
  margin-bottom: 10px;
  border-radius: 10px;

  &:hover {
    background-color: $grey-300;
  }
}

.candidature-message {
  border-radius: 8px;
  padding: 10px 15px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: $green-200;
  width: fit-content;
  margin-bottom: 10px;

  svg {
    margin-right: 15px;
    color: $green-500;
  }

  display: flex;
  align-items: center;
}

.candidature-info-tag {
  margin: 10px;
  border-radius: 8px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: $blue-200;

  p {
    margin: 0;
  }

  a {
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }

  &.proposed {
    display: block;
    background-color: $orange-100;

    .action-btns {
      display: flex;
      gap: 10px;
      margin-top: 15px;
    }
  }

  &.canceled {
    background-color: $red-200;
  }

  &.booked {
    background-color: $green-200;
  }


}

.candidature-page {
  display: flex;
  gap: 20px;
  padding: 20px;
  overflow: hidden;
}

.candidature-details {
  flex: 13;
  display: flex;
  flex-direction: column;

  & > button {
    width: max-content;
    align-self: flex-end;
  }

  .msg-warning {
    display: flex;
    gap: 10px;
    align-items: center;

    p {
      margin: 0;
    }
  }

  .agency-comment {
    background-color: $grey-300;
    padding: 15px;
    border-radius: 14px;

    .comment-header {
      display: flex;
      justify-content: space-between;
    }
  }

  .info-msg {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 10px 0;

    & > button {
      width: max-content;
      align-self: flex-end;
    }
  }

  .not-shared-msg {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.candidature-selector {
  flex: 8;
  overflow-y: scroll;
}

.properties {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.no-candidature-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.not-applied {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    color: $red-500;
    font-size: 30px;
  }
}

.section-content {
  display: flex;
  padding: 20px 15px;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  text-align: left;
}

.progress-and-name {
  display: flex;
  gap: 10px;
  align-items: center;
  min-width: 80px;
  flex: 1;
}

.add-contact-button-container {
  &:hover {
    background-color: $blue-50;
  }
}

.add-contact-button {
  color: $grey-500;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 70px;
  padding-left: 15px;
  width: 100%;
}

.candidatures-tab {
  padding: 10px;

  .main-content {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    .properties {
      flex: 1;

      .agency-card {
        &.candidatures-tab-page {
          display: flex;
          gap: 40px;

          .candidature-item {
            flex: 1;
          }

          .actions {
            flex: 1;

            .link-button {
              display: flex;
              align-items: center;
              gap: 20px;

              &.remove {
                color: $red-500;
              }
            }
          }
        }
      }
    }
  }

  .missing-contact-info {
    flex: 1;
  }
}

.modal-add-contact {
  width: 50vw;
  max-width: 300px;

  .doc-group-lines-container {
    .doc-group-line {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;

      .doc-name {
        font-weight: bolder;
        text-transform: capitalize;
      }

      .status {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100px;

        &.validated {
          color: $green-300;
        }
      }
    }
  }
}


@media (max-width: $client-mobile-width) {
  .candidature-item {
    &.selected {
      border: unset;
    }
  }
  .candidature-page {
    flex-direction: column;
    overflow: visible;

    .candidature-selector {
      overflow-y: unset;
    }
  }

  .properties {
    min-width: unset;
  }

  .candidatures-tab {
    gap: 20px;

    .main-content {
      .properties {
        gap: 20px;

        .agency-card {
          &.candidatures-tab-page {
            flex-direction: column;
            gap: 10px;
          }
        }
      }

      .actions {
        .MuiFormGroup-root {
          border: 1px $grey-400 solid;
          border-radius: 20px;

          .MuiFormControlLabel-labelPlacementStart {
            padding: 0 10px;
            margin: 0;
            justify-content: space-between;
          }
        }
      }
    }
  }
}

.tag-selector-container {
  display: flex;
  flex-direction: column;

  button {
    text-align: left;
    padding: 10px 20px;
    border-top: solid 1px $grey-300;
    &:hover {
      background-color: $grey-300;
    }

    &.selected {
      background-color: $grey-400;
    }

    &:first-child {
      border-top: none;
    }
  }
}