.settings-page {
  .login-button-container {
    margin-bottom: 20px;

    a {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .settings-button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
  }

  .material-textfield-wrapper {
    .MuiFilledInput-root {
      background-color: white;
    }
  }
}
