.circular-container{
  position: relative;
  color: $grey-400;

  .progress-top{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    &.complete{
      color: $green-300;
    }
  }
  .value-container{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .value-content{
      font-size: 12px;
      color: $grey-700;
    }
  }
}
