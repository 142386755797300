.form-page {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;

  .form-page-content {
    flex: 1;
    display: flex;
    justify-content: center;

    & > div {
      max-width: 800px;
      flex: 1;
    }

    & > form {
      max-width: 800px;
      flex: 1;
    }

    & > .property-selector-question {
      max-width: unset;
    }
  }
}

.form-container {
  height: 100vh;
  overflow: hidden;
  position: relative;

  .display-name {
    position: fixed;
    top: 50px;
    left: 50px;
    background-color: white;
    z-index: 1;
    box-shadow: $surround-shadow-light;
    padding: 15px;
    font-size: 20px;
    border-radius: 8px;

  }

  .navigation-button {
    position: fixed;
    bottom: 40px;
    left: 40px;
    background-color: white;
    z-index: 1;
    box-shadow: $surround-shadow-light;
    border-radius: 8px;

    .disabled {
      background-color: transparent;
      color: rgba(0, 0, 0, 0.26);
      pointer-events: none;
      cursor: default;
    }
  }

  .progess-bar-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    .MuiLinearProgress-root {
      height: 6px !important;
    }
  }
}

.question-container {
  padding: 30px;
  .multiple-options {
    color: $grey-500;
    font-size: 14px;
  }
  .list-choices {
    display: flex;
    gap: 20px;
    margin: 20px 0;
    flex-wrap: wrap;
    .list-choice {
      border: 2px $grey-300 solid;
      padding: 10px 20px;
      border-radius: 18px;
      transition: border-color 0.3s;
      &.selected {
        border-color: #196DDD;
      }
    }
  }
  .error-message {
    color: $red-700;
    margin: 15px 0;
    display: block;
  }
}

.form-button-container {
  display: flex;

  .validate-button {
    margin-right: 10px;
  }
}


.property-selector-question {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .no-property-container {
    padding: 0 20px 10px;
  }

  h5 {
    padding: 0 20px;
  }

  .material-textfield-wrapper {
    padding: 0 20px;
  }

  .property-validator {
    position: absolute;
    bottom: 40px;
    text-align: center;
    pointer-events: none;
    width: 100%;

    button {
      pointer-events: all;
    }
  }
}

.candidate-save-message{
  font-style: italic;
  margin: 20px 0;
}