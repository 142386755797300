.visit-item {
  margin: 0 10px 10px;
  display: block;

  .visit-item-content {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: $surround-shadow-light;
    background-color: white;
  }

  .visit-item-header {
    padding: 15px 10px;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    gap: 20px;

    svg {
      height: 20px;
    }
  }

  &.cancel {
    .visit-item-header {
      background-color: $orange-400;
      color: white;
    }
  }

  .agent-infos {
    white-space: pre-wrap;
    border-top: solid 1px $grey-300;
    padding: 20px;
    display: flex;
    gap: 15px;

    .agent-infos-body {
      font-size: 18px;
      font-weight: 300;
    }

    .agent-infos-header {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .property-list-item {
    border-bottom: none;

    .property-image {
      height: 50px;
      width: 50px;
      border-radius: 8px;
    }

    .property-info {
      flex-direction: column;

      .property-address {
        margin-top: 5px;
      }

      .property-price {
        font-weight: 500;
        font-size: 16px;
      }

      .price-month {
        font-weight: 200;
        font-size: 12px;
        color: $grey-700;

      }
    }
  }
}

.visit-booking-page {
  padding: 40px 20px 20px;

  .visit-booking-page-body {
    display: flex;
    gap: 10px;
    align-items: flex-start;

    .property-card-container {
      background-color: white;
      border-radius: 12px;
      box-shadow: $surround-shadow-mid-light;
    }
  }
}

.booking-confirm {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    min-width: 400px;
    width: 50%;
    margin-bottom: 10px;
  }
}

.booking-selection {
  display: flex;
  flex-direction: column;
  align-items: center;

  .selection-button-container {
    display: flex;
    margin-bottom: 10px;
    gap: 15px;
    width: 550px;

    button {
      flex: 1;
    }
  }
}

.visit-booking-confirm-page {
  padding: 40px 20px 20px;

}


.visit-confirm-tile-container {
  .visit-confirm-tile {
    margin-right: auto;
    margin-left: auto;
    background-color: $grey-300;
    border-radius: 16px;
    position: relative;
    padding: 20px;
    max-width: 900px;
    text-align: center;

    .add-to-calendar-button {
      margin: auto;
    }

    .personal-space-button {
      white-space: nowrap;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%);
    }


    .check-icon-container {
      position: absolute;
      height: 56px;
      width: 56px;
      top: 0;
      border: solid 4px $grey-300;
      background-color: $purple-300;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 999px;
      left: 50%;

      transform: translate(-50%, -50%);
    }

    h3 {
      font-weight: 400;
      font-size: 24px;
      color: $grey-1000;
    }

    .visit-tile {
      margin: 30px auto;
      background-color: white;
      border-radius: 16px;
      color: $grey-800;
      width: fit-content;
      min-width: 450px;
      text-align: left;

      .visit-informations {
        padding: 20px 30px 30px;

        .visit-information-row {
          margin-top: 10px;
        }
      }
    }
  }
}

.visit-tile-actions {
  border-top: solid 1px $grey-300;
  display: flex;

  .visit-action {
    white-space: nowrap;
    flex: 1;
    text-align: center;
    padding: 25px;
    justify-content: center;

    &:last-child {
      color: $red-500;
    }

    &:disabled {
      color: $grey-500;
    }
  }
}

.text-icon {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    height: 20px;
  }
}

.visit-page {
  padding: 30px;

  .link-as-button {
    margin-bottom: 10px;
    text-align: center;
  }

  .visit-item {
    margin: 0 0 15px;
  }

  .add-to-calendar {
    width: 100%;
  }
}

.already-booked-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 300px;
  padding: 15px;
  text-align: center;
  font-weight: bolder;
}

@media (max-width: $client-mobile-width) {
  .booking-confirm {
    button {
      min-width: 400px;
    }
  }
  .visit-booking-page {
    overflow-y: auto;

    .visit-booking-page-body {
      flex-direction: column-reverse;
    }
  }
  .booking-selection {
    .selection-button-container {
      width: 95%;

      button {
        font-size: 12px;
        white-space: nowrap;
      }
    }
  }
  .visit-confirm-tile-container {
    .visit-confirm-tile {
      .visit-tile {
        min-width: unset;
        max-width: 100%;

        .visit-informations {
          .visit-information-row {
          }
        }
      }
    }
  }
  .visit-tile-actions {
    .visit-action {
      font-size: 14px;
      padding: 20px 10px;
      justify-content: center;
    }
  }
}