.application-tab-container {
  margin: 0 10px 10px;
  border-bottom: solid 1px $grey-400;
}

.application-informations {
  padding: 15px;

  .material-textfield-wrapper {
    .MuiFilledInput-root {
      background-color: $grey-300;
    }
  }

  .material-select-wrapper {
    .MuiFilledInput-root {
      background-color: $grey-300;
    }
  }
}

.client-application {
  display: flex;
  text-align: left;
  background: white;
  border-radius: 10px;
  box-shadow: $surround-shadow-extra-light;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 20px;
  min-width: 330px;
  height: 80px;

  &.selected {
    border: 1px $blue-200 solid;
    background-color: $blue-100;
  }

  .progress {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .recycle-bin {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .group-name {
    flex: 1;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }
}


.application-list-container {
  position: relative;
  overflow-y: auto;
  &:hover {
    .scroll-btn {
      opacity: 1;
    }
  }
  .application-list {
    padding: 10px;
    display: flex;
    gap: 10px;
    overflow-x: scroll;

    &.application-page {
      flex-wrap: wrap;
    }

    &.application-details-page {
      flex-wrap: nowrap;
    }
  }

  .scroll-btn {
    position: absolute;
    background-color: white;
    align-self: center;
    top: 50%;
    box-shadow: $surround-shadow-light;
    opacity: 0;
    transition: 0.5s opacity;

    &:hover {
      background-color: white;
    }

    &.scroll-right {
      right: 20px;
      transform: translateY(-50%);
    }

    &.scroll-left {
      left: 20px;
      transform: translateY(-50%);
    }
  }
}

@media (max-width: $client-mobile-width) {
  body {
    overscroll-behavior-y: contain;
  }

  .application-list-container {
    .application-list {
      flex-direction: column;
      margin-bottom: 20px;

      &.application-page {
        flex-wrap: nowrap;
      }

      &.application-details-page {
        flex-direction: row;
      }
    }

    .client-application {
      height: 60px;
      min-height: 60px;

      .progress {
        position: static;
        margin-left: 10px;
      }

      .recycle-bin {
        position: static;
      }
    }
  }
}

.application-create-buttons{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  button{
    width: 100%;
  }
}

.select-type-page{
  padding: 15px;
}