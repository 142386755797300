.search-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  .header-action-container{
    display: flex;
    align-items: center;
  }
}

.property-list-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  padding: 10px;
}

.search-header-container {
  padding: 10px;
  margin-bottom: 10px;
}

.form-search {
  .quit-form-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}

@media (max-width: $client-mobile-width) {
  .property-list-container {
    justify-content: center;
  }
}

.search-creation-funnel {
  background: linear-gradient(180deg, #FFB241 0%, rgba(255, 201, 121, 0) 100%);

  .form-search {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 1;

    h3 {
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 50px;
    }

    .range-chart-container {
      height: 100px;
    }

    .count-by-day-container {
      height: 80px;

      .count-by-day {
        border: solid 1px black;
        width: 100%;
        text-align: center;
        padding: 10px 0;
        border-radius: 15px;
      }
    }

    .current-question-container {
      flex: 1;
    }

    .search-footer {
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .arrow-button-container {
        border-radius: 15px;
        padding: 5px;
        background-color: white;

        .submit-search-button {
          height: 40px;
          display: flex;
          align-items: center;
          padding: 0 10px;
        }
      }
    }
  }

  .list-wrapper {
    .list-choices {
      .list-choice {
        background-color: white;
        border-color: white;

        &.selected {
          border-color: $orange-600;
        }
      }
    }
  }
}

.search-form {
  width: 625px;
  max-width: 100vw;

  h3 {
    margin-top: 0;
    font-weight: 500;
  }

  h4 {
    font-weight: 400;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .form-block {
    padding: 10px 20px;
    border-top: solid 1px $grey-300;
  }
}

.close-drawer-button {
  font-size: 20px;
  padding: 20px;
  color: $grey-900;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}


.search-list {
  display: flex;
  flex-direction: column;

  .search-item {
    padding: 10px;
    border-bottom: solid 1px $grey-400;
    text-align: left;
  }

  & > button {
    padding: 20px;
    color: $grey-600;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.search-item {
  .search-item-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .search-item-name {
      font-size: 18px;
      font-weight: 400;
    }
  }

  .search-item-actions {
    display: flex;
    align-items: center;
    pointer-events: none;

    button {
      pointer-events: all;
    }

  }

  .search-item-body {
    font-size: 13px;
    color: $grey-900;
  }
}

.search-list-button {
  background-color: white;
  padding: 10px 20px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  gap: 20px;
  overflow: hidden;
  flex: 1;
  max-width: 400px;
  span{
    flex: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    transform: rotate(-90deg);
    font-size: 22px;
    transition: .2s;
  }

  &.active {
    svg {
      transform: rotate(90deg);
    }
  }
}

.search-sort {
  min-width: 300px;
  h3 {
    padding-left: 10px;
  }
}

.search-landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    padding: 10px;
    font-size: 30px;
    font-weight: 400;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .house-icon-container {
    width: 150px;
    height: 150px;
    position: relative;

    .house-icon-background {
      position: absolute;
      height: 90%;
      width: 90%;
      left: 35px;
      top: 35px;
      background-color: $orange-400;
      border-radius: 25px;
      z-index: 0;
      opacity: 0.5;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
    }
  }
}