.property-list-item {
  display: flex;
  align-items: center;
  color: $grey-1000;
  padding: 10px 0;
  margin: 0 20px;
  gap: 20px;
  border-bottom: solid 1px $grey-300;

  svg {
    margin-right: 3px;
    height: 14px;
  }

  .property-steps-status {
    display: flex;
    flex-wrap: wrap;
  }

  .property-info {
    flex: 4;
    display: flex;
    font-size: 14px;

    & > div {
      &:first-child {
        flex: 1;
      }
    }
  }

  .property-dates {
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .property-steps-status {
    flex: 4;
    gap: 15px;
  }

  .property-image {
    position: relative;
    height: 110px;
    width: 110px;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    overflow: hidden;
    border-radius: 18px;
    margin-right: 10px;

    .image-tag-container {
      position: absolute;
      left: 10px;
      top: 10px;

      .image-tag {
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 10px;

        &.success {
          color: $green-500;
          background-color: $green-200;
        }

        &.error {
          color: $red-500;
          background-color: $red-200;
        }
      }
    }
  }

  .property-address {
    margin-top: 10px;
  }

  .menu-container {
    svg {
      height: 1em;
      margin-right: unset;
    }
  }

  .property-identifier {
    flex: 1;
    color: $grey-1000;
    text-align: center;
    font-size: 0.8rem;
  }

  .property-link {
    border-radius: 999px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      padding-top: 7px;
      padding-left: 2px;
    }

    &:hover {
      background-color: $grey-300;
    }
  }
}


.property-list-item {
  &.property-selector {
    flex: 1;
    padding: 10px 0;
    margin: 0;

    .property-price {
      margin-bottom: 0;
    }

    .property-address {
      margin-top: 0;
    }

    .property-image {
      height: 60px;
      width: 60px;
    }

    .image-tag-container {
      .image-tag {
        padding: 0;
        height: 13px;
        width: 13px;
        border-radius: 10px;
      }
    }
  }
}
