.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  min-height: 100vh;

  h1 {
    margin-top: 40px;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .logo {
    width: 170px;
    margin-top: 20px;
  }

  .switch-button {
    margin-top: 10px;
    margin-bottom: 5px;
    text-decoration: underline;
  }

  .sign-in-button {
    text-decoration: underline;
    margin-left: 5px;
  }

  .login-card {
    h2 {
      text-align: left;
      font-size: 30px;
      margin-top: 0;
      margin-bottom: 30px;
    }

    padding: 20px;
    width: 350px;
    text-align: center;

    button {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .landing-card {
    text-align: left;

    a {
      text-align: center;
      margin-top: 10px;
      width: 100%;
      box-sizing: border-box;
    }

    .landing-message {
      margin-bottom: 30px;
    }
  }

  .signin-card {
    h2 {
      margin-bottom: 5px;
    }

    .fill-data-text {
      margin-bottom: 25px;
      text-align: left;
    }
  }

  h5 {
    margin-top: 10px;
  }

  .connection-button {
    width: 100%;
  }
}

.mail-error {
  color: $red-700;
}