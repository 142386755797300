
.booking-calendar-container {
  background-color: $grey-300;
  border-radius: 20px;
  padding: 10px;
  width: fit-content;
  text-align: center;
  flex: 1;
  .marketplace-link{
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .next-event-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
  }

  .booking-calendar {
    display: flex;

    .day-header {
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $grey-400;
    }

    & > .day-header {
      width: 80px;

      &:first-child {
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
      }
    }

    .calendar-column {
      flex: 1;
    }

    .event-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
    }
  }
}

@media (max-width: $client-mobile-width) {
  .booking-calendar-container {
    width: 100%;
    padding: 10px 0;

    .booking-calendar {
      & > .day-header {
        width: 50px;
      }
    }
  }
}