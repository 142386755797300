.carrousel-modal {
  padding-top: 50px;
  .close-button {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 50px;
    color: white;
    z-index: 99;
    stroke: black;
    svg{
      height: 40px;
    }
  }

  .modal-content {
    height: calc(100% - 100px);
    width: calc(100% - 100px);
    overflow: hidden;

    .carousel-custom-root {
      height: 100%;
    }

    .carousel-slider{
      height: 100%;
    }
    .carousel {
      .slider-wrapper {
        height: 100%;
      }

      .slider {
        height: 100%;

        li {
          height: 100%;
          max-height: unset;
        }

        .slide {
          div {
            height: 100%;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            max-height: none;
          }
        }
      }
    }
  }
}

.carousel-root {
  .carrousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    z-index: 9;

    &.right-arrow {
      right: 7px;
    }

    &.left-arrow {
      left: 7px;
    }
  }

  &:hover {
    .carrousel-arrow {
      svg{
        height: 20px;
      }
      display: block;
    }
  }
}

@media (max-width: $client-mobile-width) {
  .carrousel-modal {
    padding-top: 0;
    .modal-content {
      background-color: $grey-900;
      width: 100%;
      height: 100%;
    }
  }
}