.form-text-page {
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  display: flex;
.form-text-content-container{
  max-height: 100vh;
  overflow: auto;
}
  .form-text-content {
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px 80px;

    .form-insert {
      padding: 20px;
      margin-top: 40px;
      background-color: $grey-200;
      border-radius: 15px;
      border: 1px $grey-300 solid;
      text-align: center;

      a {
        color: inherit;
        text-decoration: inherit;
        width: min-content;
        display: block;
        margin: 0 auto;
        button.mui-button.MuiButton-root {
          margin-top: 0;
        }
      }
    }

    button{
      margin-top: 20px;
    }

    .form-text {
      margin-top: 20px;
      text-align: center;
      font-size: 18px;

      &.form-text-subtitle {
        font-size: 15px;
        color: $grey-700;
        margin-bottom: 10px;
      }

      a {
        margin: 0 3px;
        text-decoration: underline;

      }
    }
    .agency-name{
      font-size: 35px;
      margin-bottom: 40px;
      color: $grey-900;
    }
    .image-container {
      width: 200px;

      img {
        width: 100%;
      }
    }
  }
}