.nav-bar {
  z-index: 1;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.03);

  .nav-content {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 5px;
  }

  .nab-bar-header {
    img {
      width: 170px;
    }
  }

  .nav-item {
    display: flex;
    align-items: center;
    padding: 20px 10px;
    color: $grey-500;
    border-radius: 18px;
    width: 180px;
    font-weight: 500;
    font-size: 16px;

    span {
      flex: 1;
    }

    svg {
      height: 19px;

    }

    &.active {
      background-color: $grey-300;
      color: black;
    }
  }

  .log-out-button {
    margin-top: 30px;
    text-decoration: underline;
    color: $grey-500;
  }
}

@media (max-width: $client-mobile-width) {
  .nav-bar {
    height: 68px;
    box-shadow: none;

    .nav-content {
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.03);
      position: fixed;
      flex-direction: row;
      padding: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;

    }

    .nab-bar-header {
      display: none;
    }

    .log-out-button {
      display: none;
    }

    .nav-item {
      flex-direction: column;
      padding: 15px 0px;
      font-weight: 400;
      font-size: 13px;

      & > svg {
        margin-right: 0;

        &:last-child {
          display: none;
        }
      }

      &.active {
        background-color: unset;
      }
    }
  }
}

.help-button {
  margin-top: 20px;
  text-decoration: underline;

  &.logout {
    color: $red-500;
  }
}
