.range-chart-container{
  position: relative;
  margin-bottom: 20px;
  height: 200px;
  .range-slider{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(55%);
    .MuiSlider-track{
      color: $grey-500;
    }
    .MuiSlider-thumb{
      color: $grey-600;
    }
    .MuiSlider-rail{
      color: $grey-400;
    }
  }
}