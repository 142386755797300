.dialog-wrapper {
  position: relative;
  margin: auto;

  h2:first-of-type {
    padding-top: 24px;
  }

  .close-btn-container {
    position: absolute;
    width: 34px;
    top: 3px;
    right: 3px;

    button {
      padding: 5px;
    }
  }
}
