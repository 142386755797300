.tag-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 10px;
  height: fit-content;

  .tag {
    height: 21px;
    font-size: 12px;
    padding: 1px 6px;
    border-radius: 10px;
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
    }
  }

  .tag-warning {
    color: white;
    background-color: $orange-600;
  }

  .tag-info {
    color: white;
    background-color: $blue-600;
  }

  .tag-success {
    color: white;
    background-color: $green-500;
  }

  .candidature-tag {
    color: white;

    &.asked {
      background-color: $blue-600;
    }

    &.sent {
      background-color: $blue-700;
    }

    &.missing {
      background-color: $orange-500;
    }

    &.past-visit {
      background-color: $green-400;
    }

    &.futur-visit {
      background-color: $orange-500;
    }

    &.canceled-visit {
      background-color: $red-200;
    }

    &.rejected {
      background-color: $red-200;
    }

    &.proposed-visit {
      background-color: $orange-200;
    }
  }
}

.visit-tag-container {
  width: 100%;
  border-radius: 999px;
  box-sizing: border-box;
  padding: 2px 20px;

  &.past-visit {
    background-color: $grey-400;

  }

  &.futur-visit{
    border: solid 1px $grey-400;
    background-color: white;

  }

  .visit-tag {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .visit-tag-date {
      gap: 5px;
      display: flex;
      align-items: center;
    }
  }
}
