.material-textfield-wrapper {
  margin-bottom: 15px;

  .MuiFilledInput-root {
    border: none;
    overflow: hidden;
    color: $grey-900;
    background-color: $grey-200;
    border-radius: 18px;

    &:hover {
      background-color: $grey-300;
    }

    &.Mui-focused {
      background-color: $grey-300;
    }
  }
}

.PhoneInput {
  .PhoneInputCountry {
    margin-bottom: 15px;
  }

  .material-textfield-wrapper {
    flex: 1;
  }
}

.material-select-wrapper {
  margin-bottom: 15px;

  .MuiFilledInput-root {
    border: none;
    overflow: hidden;
    min-width: 200px;
    color: $grey-900;
    background-color: $grey-200;
    border-radius: 18px;

    &:hover {
      background-color: $grey-300;
    }

    &.Mui-focused {
      background-color: $grey-300;
    }

    .MuiListItemText-root {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}


.mui-button {
  &.MuiButton-root {
    padding: 10px 20px;
    border-radius: 18px;
    gap: 5px;
  }

  &.fifth {
    background-color: transparent;
    border: solid 1px $grey-900;
    color: $grey-900;
    box-shadow: none;

    &:active {
      box-shadow: none;
    }

    &:hover {
      background-color: $grey-100;
      box-shadow: none;
    }
  }
}

.action-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 16px;
}

.MuiMenu-root {
  .MuiMenu-paper {
    border-radius: 15px;
  }
}


.rounded-button {
  &.MuiButton-root {
    line-height: 0;
    min-width: unset;
    padding: 7px;
    border-radius: 999px;
  }
}


.rounded-icon-button {
  &.MuiButtonBase-root {
    background-color: white;
    padding: 5px;
    &:hover {
      background-color: $grey-400;
    }
  }
}

.material-radio-wrapper {
  flex: 1;

  .MuiFormGroup-root {
    justify-content: space-evenly;
    gap: 10px;
  }

  .MuiFormControlLabel-root {
    margin: 0;
  }

  &.hide-button {
    .MuiButtonBase-root {
      display: none;
    }

    .MuiTypography-root {
      padding: 0 14px;
    }
  }
}


.radio-list{
  .MuiFormGroup-row{
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .radio-selected{
    color: white;
    background-color: $grey-400;
  }
  .MuiFormControlLabel-root{
    padding: 10px 5px;
    border-top: solid 1px $grey-400;
    &:first-child{
      border-top: none;
    }
  }
}

.list-wrapper {
  .multiple-options {
    color: $grey-500;
    font-size: 14px;
  }

  .list-choices {
    display: flex;
    gap: 20px;
    margin: 20px 0;
    flex-wrap: wrap;

    .list-choice {
      border: 2px $grey-300 solid;
      padding: 10px 20px;
      border-radius: 18px;
      transition: border-color 0.3s;

      &.selected {
        border-color: #196DDD;
      }
    }
  }

  .error-message {
    color: $red-700;
    margin: 15px 0;
    display: block;
  }
}
