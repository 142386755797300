.client-root {
  display: flex;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.page-base {
  padding: 10px;
}

.application-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 40px;
}

.page-content {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  background-color: $page-background;

  .page-header {
    display: flex;
    padding: 20px;
    flex-wrap: wrap;
    gap: 10px;

    .material-select-wrapper {
      margin-bottom: 0;
    }

    .material-textfield-wrapper {
      margin-bottom: 0;
    }
  }

  .page-actions {
    display: flex;
    justify-content: space-between;

    .actions-container {
      display: flex;
      gap: 10px;
      align-items: center;

      a {
        background-color: $grey-200;
        color: $grey-1000;
        padding: 15px 25px;
        border-radius: 18px;

        &:hover {
          background-color: $grey-300;
        }
      }
    }
  }

  .page-header-row {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
  }

  // Ne pas mettre de padding
  .page-body {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .back-button {
    width: fit-content;
    margin-bottom: 10px;
    margin-top: 20px;
    display: flex;
    margin-left: 10px;
    align-items: center;

    h2 {
      color: $grey-800;
      margin: 0;
    }

    img {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
  }

  .page-selector-container {
    .page-selector {
      padding: 5px 10px;
      border-radius: 10px;

      &.selected {
        background-color: $grey-300;
      }
    }
  }

  .MuiPopover-paper {
    border-radius: 18px !important;

    .material-select-wrapper {
      padding: 10px;
      margin-bottom: 0;
    }
  }

  .MuiPaper-root {
    max-width: 800px !important;
  }
}

@media (max-width: $client-mobile-width) {
  .client-root {
    flex-direction: column-reverse;
  }
  .MuiPaper-root {
    margin: 10px !important;
  }
  .MuiDrawer-paper {
    margin: 0 !important;
  }
}