.rounded-image{
  height: 120px;
  width: 120px;
  border-radius: 999px;
  overflow: hidden;
  position: relative;
  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.selector-and-viewer {
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  box-shadow: $surround-shadow-extra-light;
  &.contact-selector {
    border-radius: 10px;
    overflow-y: scroll;
    background-color: white;
    box-shadow: $surround-shadow-extra-light;
    margin-bottom: 20px;
    .extendable-container {
      .selector-row {
        padding: 15px;
      }
      .sub-row {
        padding: 20px 10px;
      }
    }
  }
}
